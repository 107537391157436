import React from 'react';
import image1 from './images/AISolutions1.png';
import image2 from './images/MobileApp1.png';
import image3 from './images/CloudSolutions1.png';

function Solutions() {
  return (
    <div>
        <h1>Our Solutions</h1>

        <div className='Solutions'>
            <div className='Solution'>
                <div className="image-container">
                    <img src={image1} alt="AI Solutions" className="Solution-Image"/>
                </div>
                <div className="description-container">
                    <h1>AI Solutions</h1>
                    <p>
                        Artificial Intelligence (AI) has emerged as a game-changer in the technology landscape, revolutionizing the way we live, work, and interact. Welcome to the forefront of innovation – Lamotech, where we are dedicated to developing cutting-edge AI solutions that drive transformative change across industries.
                        <br />
                        <br />
                        In today's fast-paced world, the potential of AI is limitless. At Lamotech, we are passionate about harnessing this potential to create intelligent systems that augment human capabilities, optimize processes, and solve complex challenges. Our team of talented AI specialists, data scientists, and engineers is committed to pushing the boundaries of what AI can achieve.
                        <br />
                        <br />
                        From natural language processing and computer vision to machine learning and predictive analytics, we specialize in a diverse range of AI technologies. Our goal is to empower businesses with data-driven insights, predictive capabilities, and personalized experiences, enabling them to make informed decisions and gain a competitive edge in the digital era.                    
                    </p>
                </div>
            </div>

            <div className='Solution'>
                <div className="description-container">
                    <h1>Mobile Solutions</h1>
                    <p>
                        As mobile devices become an integral part of our lives, our mission is to create seamless, user-centric experiences that elevate businesses and enrich the lives of millions around the globe.
                        <br />
                        <br />
                        At Lamotech, we understand that mobile apps have become the driving force behind modern businesses. With our team of skilled developers and designers, we craft cutting-edge mobile solutions that cater to your unique needs and propel your brand to new heights. Whether it's iOS, Android, or cross-platform development, we've got you covered.
                        <br />
                        <br />
                        Our relentless pursuit of excellence and passion for pushing boundaries ensures that our mobile solutions are not just technologically advanced but also intuitive and engaging. From captivating user interfaces to robust backend architecture, we pay meticulous attention to every detail to create apps that stand out in the crowded app stores.
                    </p>
                </div>
                <div className="image-container">
                    <img src={image2} alt="Mobile Solutions" className="Solution-Image"/>
                </div>
            </div>

            <div className='Solution'>
                <div className="image-container">
                    <img src={image3} alt="Cloud Solutions" className="Solution-Image"/>
                </div>
                <div className="description-container">
                    <h1>Cloud Solutions</h1>
                    <p>
                        Welcome to the forefront of innovation – Lamotech, where we pioneer the development of innovative cloud solutions that revolutionize how businesses operate and scale in the digital age. As cloud computing reshapes the landscape of IT infrastructure, our mission is to empower organizations with scalable, secure, and agile cloud solutions that fuel growth and drive unparalleled success.
                        <br />
                        <br />
                        Our team of cloud architects and experts are dedicated to architecting customized solutions that align with your business objectives and propel your digital aspirations to new heights.
                        <br />
                        <br />
                        In an era where data is king, we understand the paramount importance of ensuring the security and privacy of your information. Our cloud solutions are built with a strong focus on data protection, compliance, and resilience, ensuring that your critical assets are safeguarded with utmost care.
                    </p>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Solutions;