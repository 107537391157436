import React from 'react';
import image1 from './images/AISolutions1.png';
import image2 from './images/MobileApp1.png';
import image3 from './images/CloudSolutions1.png';

function Home() {
  return (
    <div>
        <h1>Innovative solutions for AI, mobility and cloud.</h1>
        <img src={image1} alt="AI Solutions" className="Home-Image"/>
        <img src={image2} alt="Mobile App" className="Home-Image"/>
        <img src={image3} alt="Cloud Solutions" className="Home-Image"/>
        <p>
          In a world where innovation drives success, businesses need transformative solutions to stay ahead of the competition. Welcome to <strong><i>Lamotech</i></strong>, your trusted partner in the realm of technology. We are a leading company specializing in the development of groundbreaking solutions harnessing the power of Artificial Intelligence (AI), mobile apps, and cloud technologies. With our expertise, we empower businesses of all sizes to thrive in the digital era and create a lasting impact on their industries.
        </p>
    </div>
  );
}

export default Home;