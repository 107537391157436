import React from 'react';
import logo1 from './images/customers/fujitsu.png';
import logo2 from './images/customers/collegesweats.png';
import logo3 from './images/customers/dansk-social-arbejde.png';
import logo4 from './images/customers/aof.png';
import logo5 from './images/customers/lavaco2.png';
import logo6 from './images/customers/coolmerch.png';
import logo7 from './images/customers/99posters.png';
import logo8 from './images/customers/mitjob.png';
import logo9 from './images/customers/festtalen-logo.png';
import logo10 from './images/customers/BlivKlarNu_Logo.avif';

function Customers() {
  return (
    <div>
        <h1>Our Customers</h1>

        <p>
            At Lamotech, we recognize that no one knows your business better than you do. Our approach is rooted in understanding your challenges, goals, and aspirations. We believe that the best solutions are born from collaborative efforts, where our expertise merges with your domain knowledge to create something extraordinary.
            <br />
            <br />
            From the very first conversation, we embark on a joint exploration of possibilities. Our experienced team of experts becomes an extension of your team, working hand in hand to ideate, innovate, and design solutions that make a real difference. Your feedback and insights are not just valued; they are essential in shaping the direction of our projects.
            <br />
            <br />
            Our commitment to co-creation is deeply ingrained in our company culture. We listen, learn, and adapt, ensuring that the solutions we develop together are precisely tailored to meet your business objectives and deliver tangible value. Your success is our success, and we take pride in seeing your organization thrive.
        </p>
        <br />
        <br />
        <a href='https://www.fujitsu.com/dk/' className="Customer-Logo-Link"><img src={logo1} alt="Fujitsu"  className="Customer-Logo" href="https://www.fujitsu.com/dk/" /></a>
        <a href='https://collegesweats.dk/' className="Customer-Logo-Link"><img src={logo2} alt="CollegeSweats" className="Customer-Logo"/></a>
        <a href='https://mitjob.ai/' className="Customer-Logo-Link"><img src={logo8} alt="MitJob" className="Customer-Logo"/></a>
        <a href='https://dansk-socialarbejde.dk/' className="Customer-Logo-Link"><img src={logo3} alt="Dansk Social Arbejde" className="Customer-Logo"/></a>
        <a href='https://aof.dk/' className="Customer-Logo-Link"><img src={logo4} alt="AOF" className="Customer-Logo"/></a>
        <a href='https://coolmerch.dk/' className="Customer-Logo-Link"><img src={logo6} alt="Coolmerch" className="Customer-Logo"/></a>
        <a href='https://www.linkedin.com/company/lavacodk/' className="Customer-Logo-Link"><img src={logo5} alt="Lavaco" className="Customer-Logo"/></a>
        <a href='https://99posters.dk/' className="Customer-Logo-Link"><img src={logo7} alt="99Posters" className="Customer-Logo"/></a>
        <a href='https://festtalen.dk/' className="Customer-Logo-Link"><img src={logo9} alt="Festtalen" className="Customer-Logo-White"/></a>
        <a href='https://blivklarnu.dk/' className="Customer-Logo-Link"><img src={logo10} alt="Bliv Klar Nu" className="Customer-Logo"/></a>
    </div>
  );
}

export default Customers;