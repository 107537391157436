import React, { useState } from 'react';
import './App.css';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Solutions from './Solutions';
import Customers from './Customers';
import logo from './logo.png';

function App() {
  const [selectedMenuItem, setSelectedMenuItem] = useState('home');

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  const handleDropdownChange = (event) => {
    setSelectedMenuItem(event.target.value);
  };

  let content;
  switch (selectedMenuItem) {
    case 'home':
      content = (
        <>
          <Home />
        </>
      );
      break;
    case 'about':
      content = (
        <>
          <About />
        </>
      );
      break;
    case 'contact':
      content = (
        <>
          <Contact />
        </>
      );
      break;
      case 'solutions':
        content = (
          <>
            <Solutions />
          </>
        );
        break;
        case 'customers':
          content = (
            <>
              <Customers />
            </>
          );
          break;
          default:
      content = (
        <>
          <Home />
        </>
      );
      break;
  }

    return (
    <div className="App">
      {/* Header Section */}
      <header className="header">
        <div className="logo"><img src={logo} className="App-logo" alt=''/></div>
        <nav className="menu">
          <ul>
            <li onClick={() => handleMenuItemClick('home')}>Home</li>
            <li onClick={() => handleMenuItemClick('solutions')}>Solutions</li>
            <li onClick={() => handleMenuItemClick('customers')}>Customers</li>
            <li onClick={() => handleMenuItemClick('contact')}>Contact</li>
          </ul>
        </nav>
      </header>

      {/* Content Section */}
      <main className="content">{content}</main>

      {/* Footer Section */}
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} Lamotech - CVR 35915206. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
