import React from 'react';
import imagePhove from './images/phone.png';
import imageEmail from './images/email.png';

function Contact() {
  return (
    <div>
        <h1>Contact us</h1>
        <br />
        <br />
        <h2>Lamotech</h2>
        <p>CVR 35915206</p>
        <p>Rytoften 5, 1.</p>
        <p>8210 Aarhus V</p>
        <p>Denmark</p>
        <br />
        <img src={imageEmail} alt="Email" />
        <br />
        <img src={imagePhove} alt="Phone" />
    </div>
  );
}

export default Contact;